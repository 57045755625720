<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->
    <form [formGroup]="addlisingFormGroup" class="formlayout" >
    <!-- Start -->
    <div class="add-listings-box">
        <h3>Basic Informations</h3>
   
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-briefcase-alt'></i> Listing Title:</label>
                    <!-- <input type="text" class="form-control" formControlName="title" id="title" [(ngModel)]="title"  placeholder="Name of your business"> -->

                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="title" id="title" [(ngModel)]="title" placeholder="Name of your business" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('title', 'required')">First Name is required</mat-error>
                    </mat-form-field>

                </div>
            </div>
                <div class="form-group">
                    <mat-form-field  >
                        <input class="matformfield fielddesign" matInput formControlName="CUST_ID" id="CUST_ID" [(ngModel)]="CUST_ID" style="display: none;"   >  
                    </mat-form-field>

                </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-duplicate'></i> Type / Category:</label>

                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="category" id="category" [(ngModel)]="category" placeholder="Category" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('category', 'required')">Category is required</mat-error>
                    </mat-form-field>
                    <!-- <select formControlName="category" id="category" [(ngModel)]="category">
                        <option>Select Category</option>
                        <option>Beauty</option>
                        <option>Child Care</option>
                        <option>Dentists</option>
                        <option>Doctors</option>
                        <option>Electrician</option>
                        <option>Fitness</option>
                        <option>Plumber</option>
                    </select> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxs-key'></i> Keywords:</label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="keyword" id="keyword" [(ngModel)]="keyword" placeholder="Keyword" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('keyword', 'required')">Keyword is required</mat-error>
                    </mat-form-field>

                    <!-- <input type="text" class="form-control" formControlName="keyword" id="keyword" [(ngModel)]="keyword" placeholder="Maximum 15 , should be separated by commas"> -->
                </div>
            </div>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Location</h3>
    
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> City:</label>

                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="city" id="city" [(ngModel)]="city" placeholder="City" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('city', 'required')">City is required</mat-error>
                    </mat-form-field>

                    <!-- <select formControlName="city" id="city" [(ngModel)]="city">
                        <option>Select City</option>
                        <option>WA</option>
                        <option>QLD</option>
                        <option>VIC</option>
                        <option>TAS</option>
                        <option>NT</option>
                        <option>ACT</option>
                        <option>Other</option>
 
                    </select> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> Address:</label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="address" id="address" [(ngModel)]="address" placeholder="Address" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('address', 'required')">Address is required</mat-error>
                    </mat-form-field>

                    <!-- <input type="text" class="form-control" formControlName="address" id="address" [(ngModel)]="address" placeholder="e.g. 55 County Laois"> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> State:</label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="state" id="state" [(ngModel)]="state" placeholder="State" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('state', 'required')">State is required</mat-error>
                    </mat-form-field>
                    <!-- <input type="text" class="form-control"  formControlName="state" id="state" [(ngModel)]="state" > -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-menu-alt-left'></i> Zip-Code:</label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="zip" id="zip" [(ngModel)]="zip" placeholder="Zip Code" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('zip', 'required')">Zip Code is required</mat-error>
                    </mat-form-field>
                    <!-- <input type="text" class="form-control" formControlName="zip" id="zip" [(ngModel)]="zip" > -->
                </div>
            </div>
        </div>
    </div>

    <div class="add-listings-box">
        <h3>Gallery</h3>

        <div class="row">
            <div class="col-md-4">
                <div class="contact-form-style mb-20">
                    <label mat-raised-button class="add-listings-box pointer" style="background-color: #0ec6c6;padding: 0 23px 7px 10px;" >
                        <input type="file" style="display:none" formControlName="document" [(ngModel)]="document" ng2FileSelect [uploader]="uploader"  accept="image/png,image/jpeg,pdf" multiple>        
                        <mat-icon style="font-size:18px">
                            <!-- attach_file -->
                        </mat-icon>Choose Images :
                    </label>
                </div>
            </div>
            <div class="col-md-8">
                <div class="contact-form-style mb-20">
                    <div ng2FileDrop [uploader]="uploader" class="drop-zone">
                        Drag and drop files to upload
                    </div>
                    <span style="color: #F44336;">{{errorMessage}} </span>
                </div>
            </div>
        </div> 

        <div class="row">
            <div class="col-md-12 mb-20">
                <table *ngIf="isUploaderLength > 0"> 
                    <thead >
                        <tr>
                            <th width="70%">File Name</th>
                            <th width="20%">Size</th>
                            <th width="10%">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploader.queue" >                                            
                            <th width="70%" style="color: #3F51B5;">
                                {{ item.file.name}}
                            </th>
                            <th width="20%" style="color: #3F51B5;">
                                <span *ngIf="item.file.size < 1000000">
                                    ({{item.file.size/1024 | number : '1.2-2'}} Kb)
                                </span>
                                <span *ngIf="item.file.size > 1000000">
                                    ({{item.file.size/1024/1024 | number : '1.2-2'}} Mb)
                                </span>                                                
                            </th>
                            <th class="text-center" width="10%" style="color: #F44336;">
                                <mat-icon (click)="item.remove()" style="cursor: pointer; width: 50px;" title="Remove">delete</mat-icon>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
        <!-- <div class="file-upload-box">
            <form action="/file-upload" class="dropzone"></form>
        </div> -->
    </div>

    <div class="add-listings-box">
        <h3>Details</h3>
    
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label><i class='bx bx-text'></i> Description:</label>
                    <mat-form-field  appearance="outline" >
                        <textarea class="matformfield fielddesign" matInput formControlName="description" id="description" [(ngModel)]="description" placeholder="Description" required></textarea>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('description', 'required')">Description is required</mat-error>
                    </mat-form-field>
                    <!-- <textarea cols="30" rows="7" class="form-control" formControlName="description" id="description" [(ngModel)]="description" placeholder="Details..."></textarea> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-envelope'></i> Email Address: <span></span></label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="email" id="email" [(ngModel)]="email" placeholder="Email" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('email', 'required')">Email is required</mat-error>
                    </mat-form-field>
                    <!-- <input type="email" class="form-control" formControlName="email" id="email" [(ngModel)]="email"> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-globe'></i> Website: <span></span></label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="website" id="website" [(ngModel)]="website" placeholder="Website" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('website', 'required')">Website is required</mat-error>
                    </mat-form-field>
                    <!-- <input type="text" class="form-control" formControlName="website" id="website" [(ngModel)]="website"> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bx-phone-call'></i> Phone: <span></span></label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="phone" id="phone" [(ngModel)]="phone" placeholder="Phone" required>
                       
                        <mat-error class="matformfielderror" *ngIf="hasError('phone', 'required')">Phone is required</mat-error>
                    </mat-form-field>
                    <!-- <input type="text" class="form-control" formControlName="phone" id="phone" [(ngModel)]="phone"> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-facebook-square'></i> Facebook: <span>(optional)</span></label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="facebook" id="facebook" [(ngModel)]="facebook" placeholder="https://www.facebook.com/">
                       
                        <!-- <mat-error class="matformfielderror" *ngIf="hasError('facebook', 'required')">Facebook is required</mat-error> -->
                    </mat-form-field>
                    <!-- <input type="text" class="form-control" formControlName="facebook" id="facebook" [(ngModel)]="facebook" placeholder="https://www.facebook.com/"> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-twitter'></i> X Twitter: <span>(optional)</span></label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="twitter" id="twitter" [(ngModel)]="twitter" placeholder="https://www.twitter.com/" required>
                       
                        <!-- <mat-error class="matformfielderror" *ngIf="hasError('twitter', 'required')">Twitter is required</mat-error> -->
                    </mat-form-field>
                    <!-- <input type="text" class="form-control" formControlName="twitter" id="twitter" [(ngModel)]="twitter" placeholder="https://www.twitter.com/"> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label><i class='bx bxl-linkedin'></i> Linkedin: <span>(optional)</span></label>
                    <mat-form-field  appearance="outline" >
                        <input class="matformfield fielddesign" matInput formControlName="linkedin" id="linkedin" [(ngModel)]="linkedin" placeholder="https://www.linkedin.com/" required>
                       
                        <!-- <mat-error class="matformfielderror" *ngIf="hasError('linkedin', 'required')">linkedin is required</mat-error> -->
                    </mat-form-field>
                    <!-- <input type="text" class="form-control" formControlName="linkedin" id="linkedin" [(ngModel)]="linkedin" placeholder="https://www.linkedin.com/"> -->
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="add-listings-box">
        <h3>Facilities</h3>
    
        <div class="form-group">
            <ul class="facilities-list">
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="free-wifi" checked>
                        <span>Free WiFi</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="parking">
                        <span>Parking</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="fitness-center" checked>
                        <span>Fitness Center</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="non-smoking-rooms">
                        <span>Non-smoking Rooms</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="airport-shuttle" checked>
                        <span>Airport Shuttle</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="air-conditioning">
                        <span>Air Conditioning</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="events">
                        <span>Events</span>
                    </label>
                </li>
                <li>
                    <label class="checkbox">
                        <input type="checkbox" name="facilities-list" value="friendly-workspace">
                        <span>Friendly Workspace</span>
                    </label>
                </li>
            </ul>
        </div>
    </div> -->

    <div class="add-listings-box">
        <h3>Pricing</h3>

        <div class="form-group">
            <label><i class='bx bx-purchase-tag'></i> Pricing:</label>

            <mat-form-field  appearance="outline" >
                <input class="matformfield fielddesign" matInput formControlName="price" id="city" [(ngModel)]="price" placeholder=" ">
               
                <!-- <mat-error class="matformfielderror" *ngIf="hasError('price', 'required')">Price is required</mat-error> -->
            </mat-form-field>

            <!-- <input type="text" class="form-control" formControlName="price" id="price" [(ngModel)]="price" placeholder="$542.00"> -->
        </div>
    </div>
            
    <div class="add-listings-btn">
        <button type="submit"  (click)="addlising()">Submit Listings</button>
    </div>
    <!-- End -->
     </form>

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->